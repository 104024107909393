import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../../components/Layout'
import SEO from '../../components/seo'
import RightSide from '../../components/rightside-general'

import BG from '../../images/awards-bg.jpg'

class ClientPortal extends React.Component {
	render() {
		const siteTitle = 'Client Portal'
		const siteDescription = this.props.data.site.siteMetadata.description

		return (
			<Layout location={this.props.location} title={siteTitle}>
				<SEO title={siteTitle} description={siteDescription} />
				<div
					id="sliderhead"
					style={{
						background: `url(${BG}) 50% 13px fixed rgb(34, 34, 34`,
						position: 'relative',
					}}
					className="blog-header2"
				/>
				<div className="container">
					<div className="row">
						<div className="col-md-8 left-list">
							<div id="contentgen">
								<h3>Client Portal</h3>
								<img
									src="https://d33wubrfki0l68.cloudfront.net/img/wsc/3627ca9f1d2aa519223d02ba8b060fdfe47cb7ea/client_portal_picture.png"
									width="100%"
									alt="Client Portal"
									className="img-fluid"
								/>
								<h4>Are you now using the FREE WSC Group Client Portal?</h4>
								<p>
									We are pleased to announce the addition of the WSC Group
									Client Portal which allows you to view your accounting and tax
									documents any time it suits you. The online Client Portal
									makes it easy for you to securely: download, upload and even
									digitally sign documents using any internet connected device.
									<br />
								</p>
								<h4>Key benefits of the Client Portal are:</h4>
								<ul>
									<li>Self-service anytime - instant, 24/7 access</li>
									<li>Secure and convenient file and document sharing</li>
									<li>Saves time and money on postage costs</li>
									<li>Easy to keep track of financial documents and files</li>
									<li>Sign electronically for quicker turnaround</li>
								</ul>
								<p>
									From 1 June 2017, there is no annual charge for this service.
								</p>
							</div>
						</div>
						<div className="col-md-4 right-list pull-right">
							<RightSide />
						</div>
					</div>
				</div>
			</Layout>
		)
	}
}

export default ClientPortal

export const ClientPortalPageQuery = graphql`
	query ClientPortalPageQuery {
		site {
			siteMetadata {
				title
				author
				description
			}
		}
	}
`
